import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);


const router = new Router({
  mode: 'history',
  routes: [
    {
      path: "/",
      redirect: '/index'
    },
    {
      path: '/index',
      name: "index",
      component: () => import('@/views/index/index.vue'),
      meta: {
        title: '首页'
      }
    },{
      path: '/login',
      name: "login",
      component: () => import('@/views/login/login.vue'),
      meta: {
        title: '登录'
      }
    },
    {
      path: "/sessionList",
      name: "sessionList",
      component: () => import('@/views/session/session.vue'),
      meta: {
        title: '赛事'
      }
    },
    {
      path: '/trackList',
      name: "trackList",
      component: () => import('@/views/track/trackList.vue'),
      meta: {
        title: '赛道'
      }
    },
    {
      path: '/team',
      name: "team",
      component: () => import('@/views/team/team.vue'),
      meta: {
        title: '团队'
      }
    },
    {
      path: '/user',
      name: "user",
      component: () => import('@/views/user/user.vue'),
      meta: {
        title: '选手'
      }
    },
    {
      path: '/bigScreen',
      name: "bigScreen",
      component: () => import('@/views/screen/index.vue'),
      meta: {
        title: '比赛成绩'
      }
    },
    {
      path: '/result',
      name: "result",
      component: () => import('@/views/result/index.vue'),
      meta: {
        title: '成绩单'
      }
    },
    {
      path: '/teamResult',
      name: "teamResult",
      component: () => import('@/views/teamResult/index.vue'),
      meta: {
        title: '团队成绩单'
      }
    }
  ]
})

// 路由守卫
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title + '- 国防动员志愿者'
  } else {
    document.title = '国防动员志愿者'
  }
  next(); // 没有启动守卫 - 随他去吧
})


export default router