import Vue from 'vue'
import App from './App.vue'
import router from "./routers";

import './assets/less/index.less';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import Vant from 'vant';
import 'vant/lib/index.css';
import { Notify } from 'vant'
import { Toast } from 'vant';
import { ImagePreview } from 'vant';
import { Dialog } from 'vant';
import Print from '@/static/js/print'
Vue.use(Print)

Vue.use(Vant);
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$notify = Notify
Vue.prototype.$toast = Toast
Vue.prototype.$imagePreview = ImagePreview
Vue.prototype.$dialog = Dialog

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
