<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="less" scoped>
#app {
  height: 100%;
  overflow: auto;
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
</style>
